<template>
<div>
  <b-row>
    <b-col md="9">
    <b-row>
      <b-col md="6">
        <h5 class="pt-3">Номер документа(RU)</h5>
        <input type="text" id="fname" name="fname" size="40">
        <h5 class="pt-3">Номер документа(UZ)</h5>
        <input type="text" id="fname" name="fname" size="40">
        <h5 class="pt-3">Номер документа(EN)</h5>
        <input type="text" id="fname" name="fname" size="40">
      </b-col>
      <b-col md="6">
        <div  class="pt-5">
          <b-form-datepicker v-model="value"
                             :min="min"
                             :max="max"
                             locale="en"
                             size="md"
          >
          </b-form-datepicker>
        </div>
        <div class="pt-5">
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </div>
      </b-col>
    </b-row>
    </b-col>
  </b-row>
</div>
</template>

<script>
export default {
  name: 'AddDocument',
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
      ],
    }
  },
}
</script>

<style scoped>

</style>
